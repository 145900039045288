import React, { useState, useEffect } from 'react';
// import { makeStyles } from 'tss-react/mui';
// import { Stepper, Step, StepLabel } from '@mui/material';
import GeneralInfoComponent from './FormComponents/GeneralInfoComponent';
import ContactComponent from './FormComponents/ContactComponent';
import UploadComponent from './FormComponents/UploadComponent';
import * as FormStyles from './FormStyles';
import CheckIcon from '@mui/icons-material/Check';
import { Link, useSearchParams } from 'react-router-dom';
import Timer from './utils/Timer';
import { RegisterBranchContext } from './Contexts/RegisterBranchContext';
import * as ApplicationApi from './utils/ApplicationApi';
import Dialog from './utils/Dialog';
import jwt_decode from "jwt-decode";
import CircularProgress from '@mui/material/CircularProgress';

const Form = () => {
  const [searchParams] = useSearchParams();

  const { LoadSavedProgress, isDataLoaded } = React.useContext(RegisterBranchContext);
  const [loading, setLoading] = React.useState(false);

  const [token] = useState(searchParams.get('ss'));
  const [decodedToken] = useState(jwt_decode(token));

  const [data, setData] = useState({
    general_info: {},
    license_info: {},
    contacts_info: {
      Owner_Contact: [],
      Department_Contact: []
    },
    upload_info: {
      authorised_signatures: []
    },
  })

  const [currentStep, setCurrentStep] = useState(1);

  // state for timer in each step of the form (general, contact, upload)
  const [generalTimerValue, setGeneralTimerValue] = useState(0);
  const [contactTimerValue, setContactTimerValue] = useState(0);
  const [uploadTimerValue, setUploadTimerValue] = useState(0);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  // function to delay the execution of a function
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  // handle next and back button click
  const handleNextClick = async () => {
    const saveResponse = await handleSaveClick();
    if (!saveResponse.success) {
      return
    }
    setCurrentStep(currentStep + 1);
  };

  const handleBackClick = async () => {
    await handleSaveClick(false);
    setCurrentStep(currentStep - 1);
  };

  // handle timer change for each step
  const handleOnGeneralTimerChange = (value) => {
    setGeneralTimerValue(value);
  };

  const handleOnContactTimerChange = (value) => {
    setContactTimerValue(value);
  };

  const handleOnUploadTimerChange = (value) => {
    setUploadTimerValue(value);
  };

  const handleOnDataChange = (newData) => {
    setData(newData);
  }

  const getCurrentStepsName = () => {
    const steps = [];
    switch (currentStep) {
      case 1:
        steps.push('general')
        steps.push('license')
        break;
      case 2:
        steps.push('contacts')
        break;
      case 3:
        steps.push('uploads')
        steps.push('signatures')
        steps.push('requests')
        break;
      default:
        break;
    }
    return steps;
  }

  const getCurrentTimerValue = () => {
    switch (currentStep) {
      case 1:
        return ["general", generalTimerValue];
      case 2:
        return ["contacts", contactTimerValue];
      case 3:
        return ["uploads", uploadTimerValue];
      default:
        return 0;
    }
  }

  const getStepData = (step) => {
    let submissionData = []

    const steps_keys = {
      'general': ["outlet_legal_name", "outlet_trade_name", "outlet_group_name",
      "billing_outlet_address", "billing_country", "billing_city", "billing_phone", "billing_po_box", "billing_nearest_landmark", 
      "delivery_outlet_address", "delivery_country", "delivery_city", "delivery_phone", "delivery_po_box", "delivery_nearest_landmark", 
      "business_sector", "service_years", "website_url"],
      'license': ["vat_number", "vat_expiration", "license_number", "license_expiration"],
      'contacts': ["Owner_Contact", "Department_Contact"],
      'bank': ["bank_name", "bank_city", "bank_account_number", "bank_iban", "bank_swift", "bank_account_type"],
      'suppliers': ["name", "contact", "address", "phone", "email"],
      'uploads': ["tradelicensefile", "ownerpassportfile", "ownervisafile", "ownereidfile", "powerofattorneyfile", "vatfile", "hasVatCert"],
      'signatures': ["authorised_signatures"],
      'requests': ["credit_limit", "credit_period", "payment_mode"]
    }

    const uploads_original_names = {
      "tradelicensefile": "license",
      "ownerpassportfile": "owner_pp",
      "ownervisafile": "owner_visa",
      "ownereidfile": "owner_eid",
      "powerofattorneyfile": "power_of_attorney",
      "vatfile": "vat"
    }

    const fieldData = {}
    // get the current step data
    switch (step) {
      case 'general':
      case 'license':

        if (data?.general_info["use_same_billing_address"] === true) {
          data.general_info["delivery_outlet_address"] = data.general_info["billing_outlet_address"]
          data.general_info["delivery_country"] = data.general_info["billing_country"]
          data.general_info["delivery_city"] = data.general_info["billing_city"]
          data.general_info["delivery_phone"] = data.general_info["billing_phone"]
          data.general_info["delivery_po_box"] = data.general_info["billing_po_box"]

          if (decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain"){
            data.general_info["delivery_nearest_landmark"] = data.general_info["billing_nearest_landmark"]
          }
        }

        for (let i = 0; i < steps_keys[step].length; i++) {
          const key = steps_keys[step][i];
          // check if the value exists
          if (data?.general_info[key] === undefined || data?.general_info[key] === null) {
            continue;
          }
          fieldData[key] = data?.general_info[key];
        }
        submissionData = fieldData;
        break;
      case 'contacts':
        const contactData = data.contacts_info;
        if (contactData.Owner_Contact){
          submissionData.push(...contactData.Owner_Contact)
        }
        
        if (contactData.Department_Contact){
          submissionData.push(...contactData.Department_Contact)
        }

        // if key has null value remove the whole key
        for (let i = 0; i < submissionData.length; i++) {
          // loop on keys
          for (const key in submissionData[i]) {
            if (submissionData[i][key] === null || submissionData[i][key] === undefined || submissionData[i][key] === "") {
              delete submissionData[i][key];
            }
          }
        }
        break;
      case 'uploads':
      case 'requests':
        for (let i = 0; i < steps_keys[step].length; i++) {
          const key = steps_keys[step][i];
          // check if the value exists
          if (data.upload_info[key] === undefined || data.upload_info[key] === null) {
            continue;
          }

          if (step === 'uploads' && uploads_original_names[key] !== undefined) {
            fieldData[uploads_original_names[key]] = data.upload_info[key];
            continue;
          }
          fieldData[key] = data.upload_info[key];
        }

        submissionData = fieldData;

        break;
        case 'signatures':
          data.upload_info?.authorised_signatures.forEach((contact) => {
            submissionData.push({
              title: contact.title,
              name: contact.name,
              phone: contact.phone,
              mobile: contact.mobile,
              email: contact.email
            })
          })
        break;
      default:
        break
    }
    return submissionData;
  }

  const handleSaveClick = async (showDialog = true, setloading = true) => {
    if (setloading){
      setLoading(true)
      await timeout(500)
    }

    // get the current step
    const currentSteps = getCurrentStepsName();

    let isError = false
    let errorResponse = ""

    // get the current timer value
    const currentTimerValue = getCurrentTimerValue();
    for (let i = 0; i < currentSteps.length; i++) {
      const stepData = getStepData(currentSteps[i]);
      let response = ''

      // loop on data and put empty values to undefined
      for (const key in stepData) {
        if (stepData[key] === "" || stepData[key] === null) {
          stepData[key] = undefined;
          continue
        }

        // loop on inner data
        // check if there is inner data
        if (typeof stepData[key] !== 'object' || (currentSteps[i] === 'uploads') ) {
          continue
        }

        for (const innerKey in stepData[key]) {
          if (stepData[key][innerKey] === "" || stepData[key][innerKey] === null) {
            stepData[key][innerKey] = undefined;
          }
        }
      }

      if (currentSteps[i] === 'license' && decodedToken.branch_name === "Oman") {
        if (data.general_info["vat_registered"] === false) {
          stepData["vat_number"] = undefined;
          stepData["vat_expiration"] = undefined;
        }
      }
      
      if (currentSteps[i] === 'uploads') {
        response = await ApplicationApi.SaveProgressUploads(token, stepData)
        response = response.data
      } else {
        response = await ApplicationApi.SaveProgress(token, currentSteps[i], stepData)
      }

      if (response.success !== 1) {
        isError = true
        errorResponse = response.message
        break
      }
    }

    if (setloading){
      setLoading(false)
    }

    if(showDialog){
      if (isError){
        setIsDialogOpen(true)
        setDialogContent(errorResponse)
      }else{
        setIsDialogOpen(true)
        setDialogContent("Your progress has been saved successfully")
      }
    }

    if (isError){
      return {success: false, message: errorResponse}
    }

    await ApplicationApi.UpdateTime(token, currentTimerValue[0], { time_spent: currentTimerValue[1] })

    return {success: true}
  }

  const handleFinishClick = async () => {
    setLoading(true)
    // check if agreement is checked
    if (data.upload_info['confirm_info'] !== "yes") {
      setLoading(false)
      setIsDialogOpen(true)
      setDialogContent("Please agree to the terms and conditions")
      return false
    }

    const handleSaveClickResponse = await handleSaveClick(false, false)

    if (!handleSaveClickResponse.success) {
      setLoading(false)
      setIsDialogOpen(true)
      setDialogContent(handleSaveClickResponse.message)
      return
    }

    const response = await ApplicationApi.Finish(token)

    setLoading(false)
    setIsDialogOpen(true)
    if (response.success !== 1) {
      setDialogContent(response.message)
      return false
    }else{
      setDialogContent("Form has been submitted successfully. An email has been sent to authorized signatories for electronic signature.")
    }
  }

  const LoadSavedProgress_Parent = async () => {
    setLoading(true)
    const loadedData = await LoadSavedProgress(token);
    if (loadedData !== data){
      setData(loadedData)
    }
    setLoading(false)
  }


  useEffect(() => {
    LoadSavedProgress_Parent()
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <FormStyles.Container>
        <FormStyles.FormContainer>
          <FormStyles.FormHeader>
            <Link to="/">
              <FormStyles.FormLogo src={require("./images/bidfood-logo.png")} alt="Logo" />
            </Link>
          </FormStyles.FormHeader>
          <FormStyles.FormDivider />
          <div>
            <FormStyles.FormName>
              Branch Registration Form
            </FormStyles.FormName>
          </div>
          <FormStyles.FormStepContainer>
            <Dialog handleDialogPopUp={isDialogOpen} dialogContent={dialogContent} setIsDialogOpen={setIsDialogOpen}></Dialog>
            <div className={`customContainer`}>
              <FormStyles.FormStep active={currentStep >= 1} className={`${currentStep === 1 ? "active" : ""}`}>
                <div className="step-number"> {currentStep < 2 ? '1' : <CheckIcon />}</div>
                <div className="step-name">General Information</div>
                {/* <div className={`form-step-divider`} /> */}
              </FormStyles.FormStep>
              <FormStyles.FormStep active={currentStep >= 2} className={`${currentStep === 2 ? "active" : ""}`}>
                <div className="step-number">{currentStep < 3 ? '2' : <CheckIcon />}</div>
                <div className="step-name">Contact</div>
                {/* <div className={`form-step-divider`} /> */}
              </FormStyles.FormStep>
              <FormStyles.FormStep active={currentStep >= 3} className={`${currentStep === 3 ? "active" : ""}`}>
                <div>
                  <div className="step-number">{currentStep < 4 ? '3' : <CheckIcon />}</div>
                  <div className="step-name">Upload</div>
                  {/* <div className={`form-step-divider`} /> */}
                </div>
              </FormStyles.FormStep>
            </div>
          </FormStyles.FormStepContainer>

          <FormStyles.FormStepCard>
            {/* <FormStyles.FormStepName>General Information</FormStyles.FormStepName>
                <FormStyles.FormStepDescription>Let's begin by filling out your general information</FormStyles.FormStepDescription> */}

            {/* if current step is 1, show the GeneralInfoComponent and stat generalcounter also do not make it count from 0 again*/}

            {loading && 
              (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                  <CircularProgress/>
                </div>
              )
            }

            {currentStep === 1 && isDataLoaded && <><GeneralInfoComponent handleOnDataChange={handleOnDataChange} decodedToken={decodedToken} /> <Timer onTimerChange={handleOnGeneralTimerChange} startFrom={generalTimerValue}/></>}
            {currentStep === 2 && isDataLoaded && <><ContactComponent handleOnDataChange={handleOnDataChange} decodedToken={decodedToken} /> <Timer onTimerChange={handleOnContactTimerChange} startFrom={contactTimerValue}/></>}
            {currentStep === 3 && isDataLoaded && <><UploadComponent handleOnDataChange={handleOnDataChange} decodedToken={decodedToken} /> <Timer onTimerChange={handleOnUploadTimerChange} startFrom={uploadTimerValue}/></>}

          </FormStyles.FormStepCard>

          <FormStyles.FormFooter>
            {/* if current step is 1, disable the back button */}
            {currentStep !== 1 && <FormStyles.FormButton className={`border_btn`} onClick={handleBackClick}>Previous</FormStyles.FormButton>}
            {/* if current step is 4, don't show the next button */}
            {currentStep !== 3 && <FormStyles.FormButton onClick={handleNextClick}>Next</FormStyles.FormButton>}
            <FormStyles.FormButton onClick={handleSaveClick}>Save</FormStyles.FormButton>
            {/* if current step is 4, show Finish button*/}
            {currentStep === 3 && <FormStyles.FormButton onClick={handleFinishClick}>Finish</FormStyles.FormButton>}
          </FormStyles.FormFooter>
        </FormStyles.FormContainer>
      </FormStyles.Container>
    </>
  );
};

export default Form;