import React, { createContext, useState } from 'react';
import * as ApplicationApi from '../utils/ApplicationApi';
import ConstructData from './ConstructData';

export const RequestCreditContext = createContext();

export const RequestCreditProvider = ({ children }) => {
  const [data, setData] = useState({
    general_info: {},
    license_info: {},
    contacts_info: {},
    references_info: {},
    upload_info: {
      authorised_signatures: [],
    },
  });

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // handle fields change in all form pages
  const updateData = (key, value) => {    

    const newData = {
      ...data,
      [key]: {
        ...data[key],
        ...value,
      },
    }


    setData(newData);
  };

  const LoadSavedProgress = async (token) => {
    const response = await ApplicationApi.LoadSavedProgress(token);
   
    if (response.success === 0 || response.success === -1) {
      setIsDataLoaded(true);
      return;
    }

    const constructedData = ConstructData(response.message);

    if (constructedData.general_info.vat_number || constructedData.general_info.vat_expiration){
      constructedData.general_info.vat_registered = true
    }

    setData(constructedData);
    setIsDataLoaded(true);
    return constructedData
  }

  const props = {
    data,
    updateData,
    LoadSavedProgress,
    isDataLoaded
  }

  return (
    <RequestCreditContext.Provider value={props}>
      {children}
    </RequestCreditContext.Provider>
  );
}

export default RequestCreditContext;