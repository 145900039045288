import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
    Grid,
    FormControlLabel,
    TextField,
    Box,
    Radio,
    RadioGroup,
    Typography,
    InputAdornment,
    Select,
    MenuItem
  } from '@mui/material';
import { LabelStyle, UploadLabelStyle, FormStepDescription, BlockTitle, CustomInputLabel} from './FormComponentsStyles';
import { RequestCreditContext } from '../Contexts/RequestCreditContext';
import DataTable from './DataTable/DataTable';

const fileTypes = ["PDF"];

function UploadComponent({ handleOnDataChange, decodedToken }) {
  const UploadLabelClass = UploadLabelStyle();
  const LabelClass = LabelStyle();

  const { data, updateData } = React.useContext(RequestCreditContext);

  // state for the whole form
  const [formState, setFormState] = useState({
    tradelicensefile: data.upload_info.tradelicensefile || null,
    ownerpassportfile: data.upload_info.ownerpassportfile || null,
    ownervisafile: data.upload_info.ownervisafile || null,
    hasVisa: data.upload_info.hasVisa || false,
    ownereidfile: data.upload_info.ownereidfile || null,
    powerofattorneyfile: data.upload_info.powerofattorneyfile || null,
    hasPowerOfAttorney: data.upload_info.hasPowerOfAttorney || false,
    vatfile: data.upload_info.vatfile || null,
    hasVatCert: data.upload_info.hasVatCert || false,
    bankcertificatefile: (decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain") ? data.upload_info.bankcertificatefile || null : null,
    hasBankCert: (decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain") ? data.upload_info.hasBankCert || false : false,
    credit_limit: data.upload_info.credit_limit || '',
    credit_period: data.upload_info.credit_period || '',
    payment_mode: (decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain") ? data.upload_info.payment_mode || "Cheque" : null,
    confirm_info: data.upload_info.confirm_info || false,
    authorised_signatures: data.upload_info.authorised_signatures || [],
    isCitizen: (decodedToken.branch_name === "Emirates") ? 'no' : false
  });

  const company_name = decodedToken.branch_name === "Oman" ? "Horeca Trading SPC" : 
                       decodedToken.branch_name === "Bahrain" ? "Horeca United Services Company W.L.L." :
                       decodedToken.branch_name === "Emirates" ? "Horeca Trade LLC" : ""
  const cr_number = decodedToken.branch_name === "Oman" ? "CR: 1199438" :
                    decodedToken.branch_name === "Bahrain" ? "CR: 78180-01" :
                    decodedToken.branch_name === "Emirates" ? "" : ""

  // function to update form data and call callback
  const handleFormDataChange = (field, value) => {
    const updatedFormState = { ...formState, [field]: value };
    // setFormState(updatedFormState);
    updateData('upload_info', updatedFormState);
    setFormState(updatedFormState);
    handleOnDataChange({ ...data, upload_info: updatedFormState });
  };

  const handleAuthorisedSignaturesChange = (newData) => {
    const convertedArray = []
    newData.forEach((row) => {
      convertedArray.push({
        title: row.Title.data,
        name: row.Name.data,
        phone: row.Phone.data,
        mobile: row.Mobile.data,
        email: row.Email.data
      })
    })

    handleFormDataChange("authorised_signatures", convertedArray)
  }

  const Authorised_Signatures_fields = [
    { field: "Title", headerName: "Title", type: "text", cellWidth: "25%", CellAlign: 'center' },
    { field: "Name", headerName: "Name", type: "text", cellWidth: "25%" },
    { field: "Phone", headerName: "Phone number", type: "text", cellWidth: "25%" },
    { field: "Mobile", headerName: "Mobile number", type: "text", cellWidth: "25%" },
    { field: "Email", headerName: "Email", type: "text", cellWidth: "25%" }
  ];

  let Authorised_Signatures_defaultRows = []

  if (formState.authorised_signatures.length > 0) {
    // Authorised_Signatures_defaultRows = formState.authorised_signatures
    formState.authorised_signatures.forEach((item, index) => {
      Authorised_Signatures_defaultRows.push({
        Title: { data: item.title, editable: true },
        Name: { data: item.name, editable: true },
        Phone: { data: item.phone, editable: true },
        Mobile: { data: item.mobile, editable: true },
        Email: { data: item.email, editable: true },
      })
    })
  }

  return (
    <div>
    <FormStepDescription>This is the final step - upload necessary files and provide your requests</FormStepDescription>
    {/* <Typography variant="h6" className={LabelClass.label}>Banking Details</Typography> */}
    <Grid container spacing={7}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" className={UploadLabelClass.label}>Trade License</Typography>
        <FileUploader
          multiple={false}
          handleChange={e => handleFormDataChange("tradelicensefile", e)}
          name="file"
          types={fileTypes}
          label={formState.tradelicensefile ? formState.tradelicensefile.name : "Upload File"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" className={UploadLabelClass.label}>Owner/Partner Passport {decodedToken.branch_name === "Bahrain" ? "(Optional)" : ""}</Typography>
        <FileUploader
          multiple={false}
          handleChange={e => handleFormDataChange("ownerpassportfile", e)}
          name="file"
          types={fileTypes}
          label={formState.ownerpassportfile ? formState.ownerpassportfile.name : "Upload File"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" className={UploadLabelClass.label}>{decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain" ? "Owner/Partner ID" : "Owner/Partner EID"}</Typography>   
        <FileUploader
          multiple={false}
          handleChange={e => handleFormDataChange("ownereidfile", e)}
          name="file"
          types={fileTypes}
          label={formState.ownereidfile ? formState.ownereidfile.name : "Upload File"}
        />
      </Grid>
      {
        (decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain") && (
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={UploadLabelClass.label}>Owner/Partner Visa {decodedToken.branch_name === "Bahrain" ? "(Optional)" : ""}</Typography>
            <FileUploader
                multiple={false}
                handleChange={e => handleFormDataChange("ownervisafile", e)}
                name="file"
                types={fileTypes}
                label={formState.ownervisafile ? formState.ownervisafile.name : "Upload File"}
            />
          </Grid>
        )
      }
    </Grid>


    {
      (decodedToken.branch_name === "Oman") && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , mt: 5 }}>
          <p>Could you confirm if you hold a Bank Certificate?</p>
          <RadioGroup onChange={e => handleFormDataChange("hasBankCert", e.target.value)} sx={{ flexDirection: 'row' }} value={formState.hasBankCert || false}>
            <FormControlLabel
              control={<Radio />}
              value="yes"
              label="Yes"
            />
            <FormControlLabel
              control={<Radio />}
              value="no"
              label="No"
            />
          </RadioGroup>

          {formState.hasBankCert === 'yes' && (
            <Grid container spacing={7}>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" className={UploadLabelClass.label}>Bank Certificate</Typography>
              <FileUploader
                  multiple={false}
                  handleChange={e => handleFormDataChange("bankcertificatefile", e)}
                  name="file"
                  types={fileTypes}
                  label={formState.bankcertificatefile ? formState.bankcertificatefile.name : "Upload File"}
              />
              </Grid>
            </Grid>
          )}
        </Box>
      )
    }

{
      (decodedToken.branch_name === "Emirates") && (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , mt: 5 }}>
        <p>Could you confirm if you are an UAE citizen ?</p>
        <RadioGroup onChange={e => 
                              handleFormDataChange("isCitizen", e.target.value) && 
                              handleFormDataChange("hasVisa", e.target.value === "yes" ? "no" : "yes")
                            } sx={{ flexDirection: 'row' }} value={formState.isCitizen || false}>
          <FormControlLabel
            control={<Radio />}
            value="yes"
            label="Yes"
          />
          <FormControlLabel
            control={<Radio />}
            value="no"
            label="No"
          />
        </RadioGroup>
        {formState.isCitizen === 'no' && (
          <Grid container spacing={7}>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={UploadLabelClass.label}>Owner/Partner Visa</Typography>
            <FileUploader
                multiple={false}
                handleChange={e => handleFormDataChange("ownervisafile", e)}
                name="file"
                types={fileTypes}
                label={formState.ownervisafile ? formState.ownervisafile.name : "Upload File"}
            />
            </Grid>
          </Grid>
        )}
      </Box>
    )}

    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , mt: 5 }}>
      <p>Could you confirm if you hold a Power of Attorney Document?</p>
      <RadioGroup onChange={e => handleFormDataChange("hasPowerOfAttorney", e.target.value)} sx={{ flexDirection: 'row' }} value={formState.hasPowerOfAttorney || false}>
        <FormControlLabel
          control={<Radio />}
          value="yes"
          label="Yes"
        />
        <FormControlLabel
          control={<Radio />}
          value="no"
          label="No"
        />
      </RadioGroup>
      {formState.hasPowerOfAttorney === 'yes' && (
        <Grid container spacing={7}>
          <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={UploadLabelClass.label}>Power of attorney</Typography>
          <FileUploader
          multiple={false}
          handleChange={e => handleFormDataChange("powerofattorneyfile", e)}
          name="file"
          types={fileTypes}
          label={formState.powerofattorneyfile ? formState.powerofattorneyfile.name : "Upload File"}
          />
          </Grid>
        </Grid>
      )}
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , mt: 5 }}>
      <p>Could you confirm if you hold a VAT Certificate?</p>
      <RadioGroup onChange={e => handleFormDataChange("hasVatCert", e.target.value)} sx={{ flexDirection: 'row' }} value={formState.hasVatCert || false}>
        <FormControlLabel
          control={<Radio />}
          value="yes"
          label="Yes"
        />
        <FormControlLabel
          control={<Radio />}
          value="no"
          label="No"
        />
      </RadioGroup>
      {formState.hasVatCert === 'yes' && (
        <Grid container spacing={7}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={UploadLabelClass.label}>VAT Certificate</Typography>
            <FileUploader
            multiple={false}
            handleChange={e => handleFormDataChange("vatfile", e)}
            name="file"
            types={fileTypes}
            label={formState.vatfile ? formState.vatfile.name : "Upload File"}
            />
          </Grid>
        </Grid>
      )}
    </Box>

    <Typography variant="h6" className={LabelClass.label}>Request credit limit (Subject to approval)</Typography>
    <Grid container spacing={7}>
        <Grid item xs={12} sm={6}>
        <TextField
        id="outlined-basic" label="Credit Limit" variant="outlined" fullWidth
        onChange={e => handleFormDataChange("credit_limit", e.target.value)}
        value={formState.credit_limit}
        InputProps={{
          endAdornment: <InputAdornment position="end">{decodedToken.branch_name === "Emirates" ? "AED" : decodedToken.branch_name === "Oman" ? "OMR" : decodedToken.branch_name === "Bahrain" ? "BHD" : ""}</InputAdornment>
        }}
        />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Select
          fullWidth
          label="Credit Period"
          onChange={(e) => handleFormDataChange('credit_period', e.target.value)}
          value={formState.credit_period}
        >
        {(() => {
          switch (decodedToken.branch_name) {
            case "Oman":
              return [
                <MenuItem key="15" value="15">15 Days</MenuItem>,
                <MenuItem key="30" value="30">30 Days</MenuItem>
              ];
            case "Bahrain":
              return [
                <MenuItem key="14" value="14">14 Days</MenuItem>,
                <MenuItem key="21" value="21">21 Days</MenuItem>,
                <MenuItem key="30" value="30">30 Days</MenuItem>
              ];
            default:
              return (
                <MenuItem value="30">30 Days</MenuItem>
              )
          }
        })()}
        </Select>
 
        <CustomInputLabel htmlFor="credit_period_input">
          Credit Period
        </CustomInputLabel>
        </Grid>
        {
          (decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain") && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className={UploadLabelClass.label}>Payment Mode</Typography>
              <Select
                value={formState.payment_mode}
                onChange={(e) => handleFormDataChange("payment_mode", e.target.value)}
                fullWidth
                inputProps={{
                  name: 'payment_mode',
                  id: 'outlined-age-native-simple',
                }}
              >
              {(() => {
                switch (decodedToken.branch_name) {
                  case "Bahrain":
                    return [
                      <MenuItem key="cash" value="Cash">Cash</MenuItem>,
                      <MenuItem key="credit-card" value="Credit Card">Credit Card</MenuItem>,
                      <MenuItem key="cheque" value="Cheque">Cheque</MenuItem>,
                      <MenuItem key="eft" value="EFT">EFT/Bank Transfer</MenuItem>
                    ];
                  case "Oman":
                    return [
                      <MenuItem key="cheque" value="Cheque">Cheque</MenuItem>,
                      <MenuItem key="eft" value="EFT">EFT/Bank Transfer</MenuItem>
                    ];
                  default:
                    return []
                }
              })()}
              </Select>
            </Grid>
          )
        }
    </Grid>

    <BlockTitle>Authorised Signatories (As per Trade License)</BlockTitle>
    <DataTable onDataTableChange={handleAuthorisedSignaturesChange} columns={Authorised_Signatures_fields} defaultRows={Authorised_Signatures_defaultRows} unique_key={'Authorised_Signatures'} maxRows={2}/>
    {/*confirm information checkbox*/}

    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 5, p: 2, bgcolor: 'grey.100' }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · All invoices are to be paid as per the approved Credit terms.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · Claims arising from invoices must be made within 7 working days.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · We agree to settle all payment on/before the due dates.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · By submitting this request, you authorise {company_name} {(decodedToken.branch_name === "Oman" || decodedToken.branch_name === "Bahrain") ? `(${cr_number})` : ""} to make inquires into the banking and business/trade references that you have supplied.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · {company_name} must be notified immediately in case of any change in the management/authorization/authorized signatories and any changes to the legal entity.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · Any change in legal status/ownership/Management of the Company & Address change shall be informed to {company_name}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · We accept all liability of all supplies made by {company_name} against purchase orders/delivery orders.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      · {company_name} reserves the right to suspend supply of material/amend the credit facility granted without notice, according to the account performance.
      </Typography>
      {(decodedToken.branch_name === "Bahrain") && (
        <Typography variant="body1" sx={{ mb: 2 }}>
        · This application is legally binding/forced without company stamp.
        </Typography>
      )}
      {(decodedToken.branch_name === "Bahrain") && (
        <Typography variant="body1" sx={{ mb: 2 }}>
        · I/We hereby certify that information enumerated above is true to our best belief and that I/We jointly and severally hold myself/ourselves indemnified to settlement of your invoices/bills signed by myself/ourselves/employee as true and correct.
        </Typography>
      )}
      {(decodedToken.branch_name === "Oman") && (
        <Typography variant="body1" sx={{ mb: 2 }}>
        · I/We, hereby declare that the information given above and in the enclosed documents is/are true to the best of my/our knowledge.
        </Typography>
      )}


      <RadioGroup onChange={e => handleFormDataChange("confirm_info", e.target.value)} sx={{ flexDirection: 'row' }} value={formState.confirm_info || ""}>
        <FormControlLabel
          control={<Radio />}
          value="yes"
          label="Agree"
        />
        <FormControlLabel
          control={<Radio />}
          value="no"
          label="Disagree"
        />
      </RadioGroup>
    </Box>
    </div>
  );
}

export default UploadComponent;